import { memo } from "react"

import { Container, Heading } from "@spatialsys/web/ui"

import { PressCarousel } from "./press-carousel"

export const Press = memo(function Press() {
  return (
    <Container>
      <Heading weight="black" className="text-m3 md:text-m2" textAlign="center">
        In The Press
      </Heading>
      <PressCarousel />
    </Container>
  )
})
